import React, { useContext, useState } from "react";
import { IntlProvider } from "react-intl";
import translations from "../../../datastore/data/translations.json";

type Locale = "fr" | "en";
const locales: Locale[] = ["fr", "en"];
const defaultLocale = locales[0];

const localStorageKey = "locale";
const localStorage = {
  get: (): Locale | undefined => {
    try {
      return typeof window !== "undefined"
        ? (window.localStorage.getItem(localStorageKey) as Locale)
        : undefined;
    } catch (err) {
      console.error(err);
      return;
    }
  },
  set: (v: Locale) => {
    try {
      if (typeof window !== "undefined") {
        window.localStorage.setItem(localStorageKey, v);
      }
    } catch (err) {
      console.error(err);
    }
  },
};

const getDefaultLocale = (): Locale =>
  localStorage.get() ||
  (typeof navigator !== "undefined" &&
    locales.find((l) => navigator.language.split("-")[0] === l)) ||
  defaultLocale;

const getMessages = (
  locale: Locale,
): Required<React.ComponentProps<typeof IntlProvider>>["messages"] =>
  (translations as {
    id: string;
    value: Record<Locale, string>;
    description: string;
  }[]).reduce(
    (res, translation) => ({
      ...res,
      [translation.id]: translation.value[locale],
    }),
    {},
  );

const LocaleContext = React.createContext<{
  locale: Locale;
  setLocale: (locale: Locale) => void;
}>({
  locale: "en",
  setLocale: () => {},
});
export const useLocale = () => useContext(LocaleContext);

export const MainProvider = ({ children }: { children: React.ReactNode }) => {
  const [locale, setLocale] = useState<Locale>(getDefaultLocale());
  return (
    <LocaleContext.Provider
      value={{
        locale,
        setLocale: (l) => {
          setLocale(l);
          localStorage.set(l);
        },
      }}
    >
      <IntlProvider locale={locale} messages={getMessages(locale)}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export const languagesSelectorLabels = [
  {
    label: "Français",
    labelShort: "Fr",
    value: "fr",
  },
  {
    label: "English",
    labelShort: "En",
    value: "en",
  },
];
